import styled from "styled-components";
import { useMediaQuery } from "@mui/material";
import TopSectionBackgroundImg from '../../assets/mwa/SignPageBackdrop.jpg';
import { BrandLogo } from "../BrandLogo/brandLogo";
import { BrandLogo1 } from "../BrandLogo/BrandLogo1";
import { Button } from "../Button/button";
import { SignInButton } from "../Button/SignInButton";
import { useEffect, useRef } from "react";
import { msalResult } from "../../authConfig";

export const deviceSize: any = {
  mobile: 768,
  tablet: 992,
  laptop: 1324,
  desktop: 2024,
}

const { theme } = msalResult;

const TopSectionContainer = styled.div`
  width: 100%;
  height: 100vh;
  background: url(${TopSectionBackgroundImg}) no-repeat;
  background-position: 0px 0px;
  background-size: cover;
  @media screen and (max-width: ${deviceSize.mobile}px) {
    height: 100%;
    background-position: 0px 0px;
  }
`;

const BackgroundFilter = styled.div`
  width: 100%;
  height: 100%;
  // background-color: rgba(1, 16, 23, 0.7);
  display: flex;
  flex-direction: column;
`;

const TopSectionInnerContainer = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-direction: column;
`;

const LogoContainer = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  margin-top: 0%;
  @media screen and (max-width: ${deviceSize.mobile}px) {
    align-items: center;
    margin-top: 3%;
  }
`;

const SloganText = styled.h3`
  margin: 0;
  line-height: 1.4;
  color: #fff;
  font-weight: 500;
  font-size: 45px;
  align-items:left;
  // padding-bottom: 2%;
  @media screen and (max-width: ${deviceSize.mobile}px) {
    font-size: 36px;
    font-weight: 700;
    font-family: ${theme?.Style?.font};
    // padding-bottom: 5%;
  };
  text-transform: capitalize;
  font: normal normal 700 52px/60px ${theme?.Style?.font}
`;

const SloganTextFooter = styled.h3`
  margin: 0;
  line-height: 1.4;
  color: #fff;
  font-weight: 500;
  // margin-left:auto;
  align-items:left;
  padding-bottom: 2%;
  padding-right: 1%;
  padding-left: 1%;
  cursor: pointer;
  @media screen and (max-width: ${deviceSize.mobile}px) {
    font-size: 36px;
    font-weight: 700;
    // padding-bottom: 5%;
  };
  text-transform: capitalize;
  font: normal normal 700 32px/45px ${theme?.Style?.font}
`;


const SloganDescription = styled.h4`
  margin: 0;
  line-height: 1.4;
  color: #fff;
  font-weight: 500;
  font-size: 25px;
  padding-bottom: 2%;
  @media screen and (max-width: ${deviceSize.mobile}px) {
    font-size: 18px;
    font-family: inherit;
    padding-bottom: 10%;
  };
  // text-transform: capitalize;
  font-family: ${theme?.Style?.font}
`;

const LazyLoadedBackground = styled.div`
  width: 100%;
  height: 100vh;
  background-size: cover
`;

export const LoginPage = (props: any) => {
    const { children } = props;
    const { theme } = msalResult;
    const isMobile = useMediaQuery('(max-width:600px)');
    const lazyBackgroundRef = useRef<any>(null);
    const redirectToWebsite = (url: any) => {
      console.log(url)
      window.open(url, "_blank");
    }

    useEffect(() => {
      const options = {
        root: null,
        rootMargin: '0px',
        threshold: 0.5,
      };

      const handleIntersection = (entries: any) => {
        entries.forEach((entry: any) => {
          if (entry.isIntersecting) {
            // Load the image when it becomes visible
            entry.target.style.backgroundImage = theme ? `url(${theme?.FileAccess?.baseUrl + theme?.LandingPage?.BackgroundImage + `?`+ theme?.FileAccess?.sasToken})` : `url(${TopSectionBackgroundImg})`;
            observer.unobserve(entry.target);
          }
        });
      };

      const observer = new IntersectionObserver(handleIntersection, options);

      if (lazyBackgroundRef.current) {
        observer.observe(lazyBackgroundRef.current);
      };

      return () => {
        if (lazyBackgroundRef.current) {
          observer.unobserve(lazyBackgroundRef?.current);
        }
      };

    },[])

  return (
  <LazyLoadedBackground ref={lazyBackgroundRef}>
    <BackgroundFilter>
      {children}
      <TopSectionInnerContainer>
        <BrandLogo1 logoSize={isMobile ? 50 : 190} hideLogo={true} logo={theme?.FileAccess?.baseUrl + theme?.LandingPage?.logoUrl + `?`+ theme?.FileAccess?.sasToken}/>
        <LogoContainer>
          <SloganText> { theme ? theme?.LandingPage?.PageTitleMain : 'Welcome To'}</SloganText>
          <SloganText> { theme ? theme?.LandingPage?.PageTitleSubText : 'Malthe Winje Portal'}</SloganText>
          <Button bgColor={theme?.Style?.btnColor} size = {isMobile ? 2 : 12}> <SignInButton name={'Sign in'} /> </Button>
        </LogoContainer>
        {/* <BrandLogo logoSize={isMobile ? 180 : 250} /> */}
        {
          theme?.Footer?.footerLogo ? 
          <BrandLogo1 logoSize={isMobile ? 50 : 190} hideLogo={true} />
          :
          (
            <div className="foot" style={{ display:'flex', width:'100%', justifyContent:'end'}}>
                      <SloganTextFooter>{theme?.LandingPage?.MWTagline}  </SloganTextFooter>  
                      <hr style={{margin:'0px', height:'50%'}} />
                      <SloganTextFooter onClick={()=>{redirectToWebsite(theme?.Footer?.PrivacyPolicy)}}> Privacy Policy </SloganTextFooter>  

                      {/* <p onClick={()=>{redirectToWebsite(theme?.Footer?.CopyRightUrl)}} style={{cursor:'pointer', marginRight:'auto', color: 'white', font:`normal normal 300 12px/13px ${theme?.Style?.font}`, paddingLeft:'5%' }}> PrivacyPolicy</p> */}
            </div>
          )
        }
      </TopSectionInnerContainer>
    </BackgroundFilter>
    </LazyLoadedBackground>
  
  )
}
