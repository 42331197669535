import Applicationcard from "../components/ApplicationCard/applicationcard";
import { DocumentCard } from "../components/DocumentCard/documentCard";
import { AccessPackage } from "../components/packages/AccessPackage";

export const routesAdapter = () => {
    
    return routes;
  }
  
  const routes = [
  
      {
          label: 'home',
          path: '/',
          key: 'Home',
          status: true,
          component: Applicationcard
      },
      {
          label: 'document',
          path: '/document',
          key: 'Document',
          status: true,
          component: DocumentCard
      },
      {
        label: 'Access Package',
        path: '/access-packages',
        key: 'access-packages',
        status: true,
        component: AccessPackage
    }
  ]
  