import { AuthenticatedTemplate, UnauthenticatedTemplate, useMsal } from '@azure/msal-react';
import styled from '@emotion/styled';
import  Header  from '../Header/header';
import { LoginPage } from '../LoginPage/LoginPage';
import { deviceSize } from '../responsive/responsive';
import Footer from '../Footer/footer';
import { routesAdapter } from '../../configs/routes';
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import { getThemeDetails } from '../../services/ApiService';
import { useCallback, useEffect, useState } from 'react';
import { msalResult } from '../../authConfig';

const PageWrapper = styled.div`
  width: 100%;
  min-height: 100vh;
  padding: 0;
  margin: 0;
  display: flex;
  background:#F5F5F5;
  flex-direction: column;
  align-items: center;
`;
export const InnerPageContainer = styled.div`
  flex: 1;
  width: 100%;
  max-width: ${({ maxWidth } : any) => (maxWidth ? maxWidth : "auto")};
  // min-height: 100vh;
  //padding: 1em;
  display: flex;

  flex-direction: column;
  align-items: center;
`;

const ApplicationContainer = styled.div`
width: 100%;
max-width: ${deviceSize.laptop}px;
display: flex;
flex-direction: column;
background:"#000";
align-items: flex-start;
padding: 1em;
@media screen and (max-width: ${deviceSize.mobile}px) {
  
}
`

export const PageContainer = (props: any) => {

  const { accounts, inProgress } = useMsal();
  const [authenticated, setAuthenticated] = useState(false);

  const fetchThemeDetails = useCallback(async () => {
    try {
      const themeDetails = await getThemeDetails();
      msalResult.apim = themeDetails?.data?.apim;
      setAuthenticated(true);
    } catch (error) {
      console.error("Error fetching theme details:", error);
    }
  }, []);

  useEffect(() => {
    if (accounts.length > 0) {
      fetchThemeDetails();
    }
  }, [accounts, fetchThemeDetails]);
  return (
  <>
   <PageWrapper>
    <AuthenticatedTemplate>
      { authenticated && (
        <Router>
          <Header/>
          <InnerPageContainer>
              <ApplicationContainer>
                <Routes>
                  {
                    routesAdapter().map((route, index) => {
                      return (
                        <Route key={index} path={route.path} element={<route.component/>} />
                      )
                    })
                  }
                </Routes>
              </ApplicationContainer>
          </InnerPageContainer> 
          <Footer/>           
        </Router>
      )}
    </AuthenticatedTemplate>
    <UnauthenticatedTemplate>
      <LoginPage/>
    </UnauthenticatedTemplate>
   </PageWrapper>
  </>
)}

