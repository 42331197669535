import { useEffect, useState } from 'react'
import { getIdToken, msalResult } from '../../authConfig';
import { fetchPackages } from '../../services/ApiService';
import styled from '@emotion/styled';
import { Card, CardContent, CardMedia, CircularProgress, Grid, Pagination, Popover, Typography, useMediaQuery } from '@mui/material';
import { GetAllApplications, GetUsersIdentity } from '../../services/ApiService';
import { useTranslation } from 'react-i18next';
import { useStyle } from '../../styles/style';
import { EditApplicationCard } from '../EditCard/EditApplicationCard';
import edit from '../../assets/mwa/Edit.svg'
import { Err } from '../Error/Err';
import i18n from '../../i18n/i18n';
import { Button } from '../Button/button';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import { useNavigate } from 'react-router';

export const AccessPackage = () => {

    let [applicationDetails, setApplicationDetails] = useState([]);
    const [loading, setLoading] = useState(false);
    const [profile, setProfile] = useState(false);
    const [error, setError] = useState(false); 
    const [createNewApp, setcreateNewApp] = useState(false); 
    const [openDrawer, setOpenDrawer] = useState(false);
    const [isHoveredPD, setIsHoveredPD] = useState(true);
    const [isHoveredPL, setIsHoveredPLC] = useState(true);
    const [isHoveredSC, setIsHoveredSC] = useState(true);
    const [isHoveredVK, setIsHoveredVK] = useState(true);
    const [selectedCardIndex, setSelectedCardIndex] = useState(null);
    const [currentPage, setCurrentPage] = useState(1);
    const [perPage, setPerPage] = useState(8);
    const [searchTerm, setSearchTerm] = useState('');
    const [cardDetails, setCardDetails] = useState(null);
    const matches = useMediaQuery('(max-width:600px)');
    const startIndex = (currentPage - 1) * perPage;
    const endIndex = startIndex + perPage;
    const [securityGrp, setsecurityGrp] = useState(null);
    const [isCopied, setIsCopied] = useState(false);
    const [copiedIndex, setCopiedIndex] = useState(null);
    const [noData, setNoData] = useState(false);
    const { theme } = msalResult;

    const { classes } = useStyle();

    const navigate = useNavigate();

  
    const Profile = async () => {
      const tokenAccess = await getIdToken();
      const res = await GetUsersIdentity(tokenAccess);
      if(res.status === 200){
        setsecurityGrp(res.data);
        if(res.data.preferredLanguage === 'en-GB'){
            i18n.changeLanguage('en-GB');
        } else if(res.data.preferredLanguage === 'sv'){
            i18n.changeLanguage('se');
        } else if(res.data.preferredLanguage === 'no'){
            i18n.changeLanguage('no');
        }
     }
  
      if (res?.data?.isAdmin === true) {
        return setProfile(true)
      } else {
        setProfile(false);
      }
      return res.data;
    }

    const fethAccessPackages = async () => {
        const tokenAccess = await getIdToken();
        const res = await fetchPackages(tokenAccess);
        if(res === 401){
            setError(true);
          } else if( res === 404){
            setNoData(true);
          }
           setLoading(false);
          setApplicationDetails(res.data);
    }
  
    useEffect(() => { 
      setLoading(true);
      fethAccessPackages();
      Profile();
    }, []); 
  
    const { t } = useTranslation();
  
    const filteredData = applicationDetails ? applicationDetails?.filter((item : any) => {
      if (searchTerm === '') return true;
    }): [];
  
    const paginatedData = filteredData?.slice(startIndex, endIndex);
  
    const handleHover = (hoverState: any, type: any, id:any, index: any) => { 
  
        if (type === 'PrepDash') {
          setIsHoveredPD(hoverState);
          setIsHoveredPLC(false);
          setIsHoveredSC(false);
          setIsHoveredVK(false);
          setSelectedCardIndex(index)
        } else if (type === 'PLC') {
          setSelectedCardIndex(index)
          setIsHoveredPD(false);
          setIsHoveredSC(false);
          setIsHoveredVK(false);
        } else if (type === 'VannKiosk') {
          setIsHoveredVK(hoverState);
          setSelectedCardIndex(index)
          setIsHoveredPD(false);
          setIsHoveredPLC(false);
          setIsHoveredSC(false);
        } else {
          setIsHoveredSC(hoverState);
          setIsHoveredPD(false);
          setIsHoveredPLC(false);
          setIsHoveredVK(false);
          setSelectedCardIndex(index)
        }
  
    };
  
    const openAddApplication = (x: any) => {
        window.open('https://myaccess.microsoft.com/@mwcloudplatform.onmicrosoft.com#/request-approval', '_blank');
    }
  
    const handleHoverOver = (hoverState: any, type: any, id:any, index: any) => {
      if (type === 'PrepDash') {
        setIsHoveredPD(hoverState);
        setIsHoveredPLC(false);
        setIsHoveredSC(false);
        setIsHoveredVK(false);
        setSelectedCardIndex(null)
      } else if (type === 'PLC') {
        setSelectedCardIndex(null)
        setIsHoveredPD(false);
        setIsHoveredSC(false);
        setIsHoveredVK(false);
      } else if (type === 'VannKiosk') {
        setIsHoveredVK(hoverState);
        setSelectedCardIndex(null)
        setIsHoveredPD(false);
        setIsHoveredPLC(false);
        setIsHoveredSC(false);
      } else {
        setIsHoveredSC(hoverState);
        setIsHoveredPD(false);
        setIsHoveredPLC(false);
        setIsHoveredVK(false);
        setSelectedCardIndex(null)
      }
    }
  
    const pageCount = isNaN(filteredData?.length) ? 0 : Math.ceil(filteredData?.length / perPage);
  
    const handlePageChange = (event: any, value: React.SetStateAction<number>) => {
      setCurrentPage(value);
    };

    const AppCard = styled(Card)(
      ({ theme }) => ({
        maxWidth: 30/0,
        width: '100%',
        height: 'auto',
        borderRadius:10,
        cursor:"pointer",
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
        transition: 'transform 0.3s ease-in-out',
        boxShadow: 'none',
    
        '&:hover': {
          // transform: 'scale(1.05)',
          // boxShadow: 'inset 0px 0px 10px rgba(0, 0, 0, 0.2)',
        },
        '@media(max-width: 600px)':{
         width:'200px'
        },
        '@media(max-width: 500px)':{
          width:'170px'
         }
      })
    );
    
    const AppCardContent = styled(CardContent)({
      display: 'flex',
      flexDirection: 'column',
      backgroundColor:"#FFFF",
      paddingBottom:'0px !important',
      textAlign: 'start',
      padding:'0px',
    });
    
    const AppTitle = styled(Typography)({
      font: `normal normal bold 16px/24px ${theme?.Style?.font}`,
      textTransform:'capitalize',
      color:'#0C1732',
      '@media(max-width: 600px)':{
        font: `normal normal bold 16px/16px ${theme?.Style?.font}`,
      },
       '@media(max-width: 500px)':{
        font: `normal normal bold 16px/16px ${theme?.Style?.font}`,
      },
    });
    
    const AppDescription = styled(Typography)({
      // fontSize: '0.5rem',
      lineHeight: '16px !important',
      color:"#0C1732",
      font: `normal normal 400 10px/22px ${theme?.Style?.font}`,
    });
  
    const Title = styled.h2`
      margin: 0;
      font-weight: 500;
      color: #000;
      text-align: start;
      font: normal normal bold 24px/60px ${theme?.Style?.font};
  
      @media(max-width: 600px){
        font: normal normal bold 20px/36px ${theme?.Style?.font} !important;
      }
    `;
  
    const TopSectionContainer = styled.div`
      display: flex;
      justify-content: space-between;
      flex-direction: column;
      gap: 20px;
      grid-template-columns: repeat(5, 4fr);
      @media screen and (max-width: 1024px) {
        grid-template-columns: repeat(3, 2fr);
        gap: 16px;
      };
      @media screen and (max-width: 1182px) {
        grid-template-columns: repeat(4, 2fr);
        gap: 16px;
      };
      @media screen and (max-width: 768px) {
        grid-template-columns: repeat(3, 1fr);
        gap: 16px;
      };
      @media screen and (max-width: 960px) {
        grid-template-columns: repeat(3, 1fr);
        gap: 16px;
      };
      @media screen and (max-width: 425px) {
        grid-template-columns: repeat(2, 1fr);
        gap: 16px;
      }
    `;

    const onCopyText = (index: any) => {
        setIsCopied(true);
        setCopiedIndex(index); // Set the copied index
        setTimeout(() => {
            setIsCopied(false);
            setCopiedIndex(null); // Reset the copied index after some time
        }, 2500); // Hide the success message after 2.5 seconds
    };
  
    return(  
      <Grid container width={'100%'}>
        {error ? 
          (
            <Err isOpen={true}/>
          ) : (
            <Grid container sx={{ pt:{md: '28px', xs:'0px', lg:'32px'}}} justifyContent={'center'}>
              <Grid display={'flex'} justifyContent={'space-between'} flexDirection={'row'} width={'100%'} container spacing={2} sx={{ pl: {xs:"10px", md:"6%", lg:""}, pb: {xs:"12px", lg:""} }}>
                <Grid item>
                  <Title>{t('accesspackages')}</Title>
                </Grid>
                {
                  profile && !loading ?
                  <Grid item pl={{sm:'0px !important', xs:'0px !important'}} pr={{sm:'10% !important', xs:'8% !important'}}>
                    <Button size = {matches ? 12 : 16} bgColor={theme?.Style?.btnColor} marginTopSize = {0} onClick={openAddApplication}>Access Packages </Button>
                  </Grid> : 
                  <></>
                }
                
              </Grid>
              {
              loading ? <CircularProgress/>
                :
                (
                  noData ? 
                  <Grid item>
                    <Title>Access Packages Not Found</Title>
                  </Grid>   
                  :
                  <TopSectionContainer>
                    {paginatedData.map((application: any, index: any) => (
                      <Grid item xs={12} md={2.4} lg={3} key={application.Id} display={'flex'} justifyContent={'center'} maxWidth={'100% !important'} position={'relative'} flexDirection={'column'}>
                        <AppCard>                  
                          <AppCardContent className={classes.cardTitle} onMouseEnter={() => handleHover(true, application?.typeName, application?.applicationId, index)}
                              onMouseLeave={() => handleHoverOver(false, application?.typeName, application?.applicationId, index)}>
                            <Grid container p={0} spacing={2} width={'100%'} margin={'0px'} minHeight={'80px'} maxHeight={'80px'} >

                              <Grid item pt={'20px !important'} pl={{md:'2% !important', xs:'10px !important', sm:'5px !important'}} xs={8}>
                                  <AppTitle>{application?.typeName === null ? 'App' : application?.DisplayName}</AppTitle>
                                  <AppDescription>{application?.Description}</AppDescription>
                              </Grid>

                              <Grid display={'flex'} justifyContent={'end'} key={index} item xs={4} margin={'0px !important'} paddingRight={'8px'}>
                                      <CopyToClipboard text={application?.RequestLink} onCopy={() => onCopyText(index)}>                      
                                          <svg height="40" width="50%" style={{fill: theme?.Style?.btnColor}}>
                                              <rect x="0" y="0" rx="6" ry="6" width="80" height="40"/>
                                              <text fill="#ffffff" fontSize="14" fontWeight="700" fontFamily= {theme?.Style?.font} textAnchor='middle' x="38" y="26">
                                                  Copy Link
                                              </text>
                                          </svg>
                                      </CopyToClipboard>   
                              </Grid>
                            </Grid>
                          </AppCardContent>
                        </AppCard>   
                        <Grid>
                            {isCopied && copiedIndex === index && <div className="alert-success" style={{fontFamily: theme?.Style?.font}}>Link Copied to clipboard!</div>}                                                                    
                        </Grid>
                      </Grid>
                    ))}
                  </TopSectionContainer>          
                )

              }
              <Grid container justifyContent={'start'} pt={2} pl={'6%'}>
                {
                  !loading && paginatedData.length > 0 && pageCount > 1 ? (
                    <div>
                  <Pagination
                   count={pageCount} page={currentPage} onChange={handlePageChange} />
                </div>
                  ) : (
                    <></>
                  )
                }
                
              </Grid>
            </Grid>
          )
    
         }
      </Grid>
      )
}
