import { Grid } from '@mui/material'
import { msalResult } from '../../authConfig'

export const InputComponent = ({register, label, type, id, name, requiredStatus, onChange}: any) => {
  const { theme } = msalResult;
  return (
    <Grid item marginTop={2}>
      {
        type === 'hidden' ? 
        <label hidden={ type } style={{fontSize:"16px", color:"#333", marginTop: "15px", marginBottom:"5px", marginLeft:"32px", font:`normal normal 700 16px/24px ${theme?.Style?.font}`}}>{label}</label>
        :
        <label style={{fontSize:"16px", color:"#333", marginTop: "15px", marginBottom:"5px", marginLeft:"32px", font:`normal normal 700 16px/24px ${theme?.Style?.font}`}}>{label}</label>
      }
        <div style={{display:'flex', justifyContent:'center'}}>
            <input 
                type={type} 
                id={id}
                style={{height:"35px", padding:"5px 16px", fontSize:"16px", width:"82%", border:"1px solid #ddd",font:`normal normal 300 14px/24px ${theme?.Style?.font}`, borderRadius:"52px",}}
                {...register(name, {required: {value: requiredStatus}})} 
                onChange={onChange}
            />  
        </div>
    </Grid>
  )
}
