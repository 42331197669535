import React from "react";
import LogoImg from "../../assets/MWLogoWhite.png";
import imgLogo from "../../assets/mwa/mwlogo.png"
import styled from "@emotion/styled";
import { msalResult } from "../../authConfig";


interface Props {
  logoSize?: any;
  textSize?: any;
  color?: any;
  hideLogo?: any;
  size?: any;
  logo?: any;
}

const BrandLogoContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
  margin-left: 12%;
  margin-top: 0%
`;

// const LogoImage = styled.div<Props>`
//   width: ${({ size }: any) => (size ?  + "px" : "")};
//   height: ${({ size }: any) => (size ? size + "px" : "1em")};
//   img {
//     height: 90%;
//   }
// `;

const LogoImage = styled.div<Props>`
  width: ${({ size }: any) => (size ? size + "px" : "")};
  height: ${({ size }: any) => (size ? size + "px" : "auto")}; /* Set height to auto */
  max-width: 100%; /* Ensure image doesn't exceed parent's width */
  max-height: 100%; /* Ensure image doesn't exceed parent's height */
  overflow: hidden; /* Hide any part of the image that exceeds the dimensions */
  
  img {
    width: 100%; /* Ensure the image fills the container */
    height: 100%; /* Ensure the image fills the container */
    object-fit: contain; /* Maintain aspect ratio and fit the entire image within the container */
  }
`;

const LogoTitle = styled.h2<Props>`
  margin: 0;
  font-size: ${({ size }: any) => (size ? size + "px" : "30px")};
  color: ${({ color }) => (color ? color : "#fff")};  
  font-weight: 900;
  margin-left: 15px;
`;

export function BrandLogo1(props: Props) {
  const { logoSize, textSize, color, hideLogo, logo } = props;
  const { theme } = msalResult;

  return (
    
    <BrandLogoContainer>
      {hideLogo && (
          <LogoImage size={logoSize}>
            <img src={logo? logo : LogoImg} alt="MW" />
          </LogoImage>
      )}
       {!hideLogo && (
          <LogoImage size={logoSize}>
            <img src={imgLogo}  alt="MW" />
          </LogoImage>
      )}
      {/* <LogoTitle size={textSize} color={color}>
        Malthe Winje
      </LogoTitle> */}
    </BrandLogoContainer>
  );
}