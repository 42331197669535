import styled from "styled-components";
import { msalResult } from "../../authConfig";

export const deviceSize: any = {
  mobile: 768,
  tablet: 992,
  laptop: 1324,
  desktop: 2024,
}

const { theme } = msalResult;
const ButtonWrapper = styled.div<any>`
  border: none;
  outline: none;
  color: #fff;
  padding: 6px 1em;
  font-size: ${({ size }: any) => (size ? size + "px" : "18px")};
  font-weight: 600;
  font-family: ${theme?.Style?.font};
  border-radius: 32px;
  background-color:${({ bgColor }: any) => (bgColor ? bgColor : "#09b885")};
  cursor: pointer;
  margin-top: ${({ marginTopSize }: any) => (marginTopSize ? marginTopSize + "px" : "24px")};
  transition: all 200ms ease-in-out;
  @media screen and (max-width: ${deviceSize.mobile}px) {
    font-size: 13px;   
    font-weight: 600;
    font-family: ${theme?.Style?.font};
    padding: 6px 1em;
    margin-top: 0px ;
    line-height: 1rem;
  };
  &:hover {
    background-color: #21867a;
  }
  &:focus {
    outline: none;
  },
`;

export function Button(props : any) {
  const { size, marginTopSize, onClick, bgColor } = props;

  return (
    <ButtonWrapper size = {size } marginTopSize={marginTopSize} bgColor={bgColor} className={props.className}  onClick={onClick}>
      {props.children}
    </ButtonWrapper>
  );
}