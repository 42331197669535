import { Grid, MenuItem, Select } from '@mui/material'
import { msalResult } from '../../authConfig'

export const SelectionInputArray = ({typeId, handleChange, applicationTypes, label, requiredStatus}: any) => {
    const { theme } = msalResult;
    return (
      <Grid item marginTop={2}>
          <label style={{fontSize:"16px", color:"#333", marginTop: "15px", marginBottom:"5px", marginLeft:"32px", font:`normal normal 700 16px/24px ${theme?.Style?.font}`}}> {label} </label>
          <div style={{display:'flex', justifyContent:'center', flexDirection:'row'}}>
              <Select
                  required={requiredStatus}
                  value={typeId}
                  onChange={handleChange}
                  displayEmpty
                  inputProps={{ 'aria-label': 'Without label' }}
                  style={{width:'90%', borderRadius:"52px"}}>
                  {
                      applicationTypes.map((item: any, index: any) => (
                          <MenuItem value={index} key={index}>{item}</MenuItem>
                      ))
                  }
              </Select>
          </div>
      </Grid>
    )
  }