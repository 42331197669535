import axios from 'axios';

export const Axiosclient = ({options, getCurrentAccessToken, getCurrentRefreshToken,refreshTokenUrl,logout,setRefreshedTokens} : any) => {
    const client = axios.create(options);

    client.interceptors.request.use((config: any) => {
        if(config.authorization !== false){
            const token = getCurrentAccessToken;
            if(token){
                config.headers.Authorization = "Bearer " +token;
                // config.headers["X-MS-TOKEN-AAD-ACCESS-TOKEN" ] = token;
            }
        }
        return config;
    }, (err: any) => {
        return Promise.reject(err);
    })
  return client;
}
