import styled from '@emotion/styled';
import { Card, CardContent, CardMedia, Grid, Pagination, Typography } from '@mui/material';
import React, { useEffect, useState } from 'react';
import pdfImg  from '../Assets/pdf.png';
import { GetDocuments } from '../../services/ApiService';


export const DocumentCard = ({ title, description, type, imageUrl }: any) => {

  const [data, setData] = useState<any>();
  const [currentPage, setCurrentPage] = useState(1);
  const [perPage, setPerPage] = useState(8);
  const [searchTerm, setSearchTerm] = useState('');
  

  const fetchDoc = async () => {
    const res = await GetDocuments();
    setData(res);
  };

  useEffect(() => { fetchDoc()  },[]);

    const filteredData = data?.filter((item : any) => {
      if (searchTerm === '') return true;
      const title = item.title.toLowerCase();
      const description = item.description.toLowerCase();
      const term = searchTerm.toLowerCase();
      return title.includes(term) || description.includes(term);
    });

    const pageCount = Math.ceil(filteredData?.length / perPage);
    const startIndex = (currentPage - 1) * perPage;
    const endIndex = startIndex + perPage;
    const paginatedData = filteredData?.slice(startIndex, endIndex);
 
  const handlePageChange = (event: any, value: React.SetStateAction<number>) => {
    setCurrentPage(value);
  };

  const handleFilterChange = (event: any) => {
    setSearchTerm(event.target.value);
    setCurrentPage(1);
  };

  const PdfCard = styled(Card)(
    ({ theme }) => ({
      maxWidth: 300,
      width: 150,
      margin: 5,
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'space-between',
      transition: 'transform 0.3s ease-in-out',
  
      '&:hover': {
        transform: 'scale(1.05)',
      },
    })
  );
  
  const PdfImage = styled(CardMedia)(
    ({ theme }) => ({
      height: 20,
      backgroundColor: 'red',
      paddingTop: '56.25%', // 16:9 aspect ratio
      backgroundSize: 'contain',
  
      '& img': {
        width: '100%',
        height: '100%',
        objectFit: 'contain',
      },
    })
  );
  
  const PdfCardContent = styled(CardContent)({
    display: 'flex',
    flexDirection: 'column',
    // justifyContent: 'space-between',
    paddingTop:8,
    paddingBottom:'15px !important',
    paddingLeft:'10px',
    paddingRight:'0px',
    textAlign: 'start'
  });
  
  const PdfTitle = styled(Typography)({
    fontSize: '1rem',
    fontWeight: 600,
  });
  
  const PdfDescription = styled(Typography)({
    fontSize: '0.9rem',
    lineHeight: '1.2',
    color:"#979797"
  });

  const Title = styled.h2`
    font-size: 25px;
    margin: 0;
    font-weight: 600;
    color: #000;
    text-align: start;
  `;

  return (
    <Grid container pt={'65px'} justifyContent={'center'}>
      <Grid display={'flex'} justifyContent={'start'} container ml={'0px'} spacing={2} sx={{ pl: {xs:"20px", md:"60px", lg:""} }} pb={1}>
        <Grid pl={'0 !important'} item>
          <Title>Documents</Title>
        </Grid>
      </Grid>
      <Grid display={'grid'} justifyContent={'center'} sx={{ gridTemplateColumns: {xs:"repeat(2, 1fr)", md:"repeat(5, 1fr)", lg:"repeat(7, 1fr)"}, gap: {xs:"2px", md:"20px", lg:"10px" }}}>
          {paginatedData?.map((document: any, index: any) => (
            <Grid item xs={12} sm={6} md={1} key={index} display={'flex'} justifyContent={'center'} maxWidth={'100% !important'}>
              <a href={document.url} style={{textDecoration:'none'}} target='_blank' rel="noreferrer">
              <PdfCard>
                <PdfImage image={!document?.type ? pdfImg : document?.imageUrl} />
                <PdfCardContent>
                  <PdfTitle>{document?.name}</PdfTitle>
                  {/* <PdfDescription>{document?.name}</PdfDescription> */}
                </PdfCardContent>
              </PdfCard>
              </a>
            </Grid>
          ))}
      </Grid>
      <Grid container justifyContent={'center'} pt={2}>
        <div>
          <Pagination count={Number.isNaN(pageCount) ? 1 : pageCount} page={currentPage} onChange={handlePageChange} />
        </div>
      </Grid>
    </Grid>
  )
};

