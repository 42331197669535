import { makeStyles } from "tss-react/mui"; 

export const useStyle = makeStyles()({ 
    drawerForm:{
        width:'34%',
        boxShadow:"none"
      },
      drawerFormMobile:{
       width:'100%'
      },
      drawerFormTablet:{
       width:'65%'
      },
      drawerMenuTablet:{
        width:'45%'
       },
      
      popupStyle:{
          width: "232px"
      },
      cardTitle:{
        transition: "transform 0.5s", // Only transform on initial state
        borderBottom:"0px solid #cfc3c3cc",
        borderLeft:"0px solid #cfc3c3cc",
        borderRight:"0px solid #cfc3c3cc",
        '&:hover':{
            transition: "border 52s, transform 55s", // Border appears 2 seconds slower
            borderTop:"0px solid #ccc",
            borderBottom:"1.2px solid #cfc3c3cc",
            borderLeft:"1.2px solid #cfc3c3cc",
            borderRight:"1.2px solid #cfc3c3cc",

            borderRadius:"0px 0px 10px 10px",
        }
      },
      imageSize:{
        '@media (min-width: 780px)': {
          height: '125px'
        },
        '@media (max-width: 780px)': {
          height: '70px'
        },
      },
      headerImage:{
        height:'60px',
        cursor: 'pointer',
        '@media (max-width: 780px)': {
          height: '35px'
        },
      }
})