import * as React from 'react';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import { useTranslation } from "react-i18next";
import England from "./../../assets/mwa/FLAG_EN.svg";
import Norway from "./../../assets/mwa/FLAG_NO.svg";
import Sweden from "./../../assets/mwa/FLAG_SE.svg";
import { ListItemIcon, ListItemText } from '@mui/material';
import { SetLanguagePreference } from '../../services/ApiService';
import { GetUsersIdentity } from '../../services/ApiService';
import { useEffect } from 'react';
import { getIdToken, msalResult } from '../../authConfig';
export default function SelectLabels() {
  const [age, setAge] = React.useState('');

  const { theme } = msalResult;

  const handleChange = (event: SelectChangeEvent) => {
    setAge(event.target.value);
  };
  const { i18n, ready } = useTranslation();
  const changeLanguage = (lng:any) => {
    i18n.changeLanguage(lng);
    setLanguage(lng === "no" ? "Norwegian" : lng === "se" ? "Swedish" : "English")
  };

  const setLanguage = async (lang: string) => {
    const tokenAccess = await getIdToken();
    SetLanguagePreference(lang, tokenAccess).then((res: any) => {
      return res;
    })
  }

  useEffect(() => {
    GetUserIdentityInformation();
  }, [])

  const GetUserIdentityInformation = async () => {
    const tokenAccess = await getIdToken();
    GetUsersIdentity(tokenAccess).then((r: any) => {
      if(r?.data?.preferredLanguage === "sv"){
        setAge("se");
        i18n.changeLanguage("se");
      } else if (r?.data?.preferredLanguage === "no"){
        setAge("no");
        i18n.changeLanguage("se");
      } else if(r?.data?.preferredLanguage === "en-GB"){
        setAge("en");
        i18n.changeLanguage("se");
      } else {
        setAge("en")                                                                                                                                                                                                                                                                                                                                                                                                                                                                              
      }
    })
  }
  return (
      <FormControl sx={{ m: 0, minWidth: 90, justifyContent:'center' }}>
        <Select
          value={age}
          onChange={handleChange}
          displayEmpty
          sx={{ boxShadow: 'none', '.MuiOutlinedInput-notchedOutline': { border: 0 }, '.css-1636szt':{color:'#ffffff', minWidth:'0px'}, '.css-1yhq19e':{color:'#fffff', minWidth:'0px'}, '.css-bpeome-MuiSvgIcon-root-MuiSelect-icon':{minWidth:'0px', color:'#ffff'}, '.css-cveggr-MuiListItemIcon-root':{minWidth:'0px', color:'#ffff'}, '.css-qiwgdb.MuiSelect-select':{padding:'5px'},'.css-hfutr2-MuiSvgIcon-root-MuiSelect-icon':{color:'#ffff'}, fontFamily: `${theme?.Style?.font} semi bold`, fontSize:'14px', display:'flex', border: '1px solid #ffff', color:'#ffff',borderRadius:'16px', padding:'1px !important', '.css-11u53oe-MuiSelect-select-MuiInputBase-input-MuiOutlinedInput-input':{padding: '5px'} }}
          inputProps={{ 'aria-label': 'Without label' }}
        >
          <MenuItem  value={"no"} onClick={() => changeLanguage("no")} style={{display:'flex'}}>
            <div style={{display:'flex'}}>
              <ListItemIcon sx={{minWidth:'24px'}}>
                <img src={Norway} style={{ width: 20, height: 20 }} alt="Norway" loading="lazy"/>
              </ListItemIcon>
              <ListItemText disableTypography primary="NO" style={{ fontFamily: theme?.Style?.font, fontSize:'14px', margin:'0px'}} />
            </div>
          </MenuItem>
         
          <MenuItem value={"se"}   onClick={() => changeLanguage("se")} style={{display: 'flex'}}>
            <div style={{display:'flex'}}>
              <ListItemIcon sx={{minWidth:'24px'}}  >
                <img src={Sweden} style={{ width: 20, height: 20 }} alt="Sweden" loading="lazy" />
              </ListItemIcon>
              <ListItemText disableTypography primary="SE" style={{fontFamily: theme?.Style?.font, fontSize:'14px', margin:'0px'}}/>
            </div>
          </MenuItem>
          <MenuItem  value={"en"} onClick={() => changeLanguage("en")} style={{display: 'flex'}}>
            <div style={{display:'flex'}}>
              <ListItemIcon sx={{minWidth:'24px'}}>
                <img src={England} style={{ width: 20, height: 20 }} alt="English" loading="lazy"/>
              </ListItemIcon>
              <ListItemText disableTypography primary="EN" style={{fontFamily: theme?.Style?.font, fontSize:'14px',margin:'0px'}} />
            </div>
          </MenuItem>
        </Select>
        
      </FormControl>
  );
}
