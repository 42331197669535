import { useEffect, useState } from "react";
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import Menu from '@mui/material/Menu';
import MenuIcon from '@mui/icons-material/Menu';
import Container from '@mui/material/Container';
import Tooltip from '@mui/material/Tooltip';
import MenuItem from '@mui/material/MenuItem';
import Logo from "../../assets/currentumlogo.png";
import { Divider, Drawer, Grid, List, ListItem, ListItemText, useMediaQuery } from '@mui/material';
import Language from "../Language/index"
import { useLocation, useNavigate } from "react-router-dom";
import { useMsal } from "@azure/msal-react"; 
import React from "react";
import { getIdToken, loginRequest, msalResult } from "../../authConfig";
import { callMsGraph } from "../../graph";
import { CloseOutlined, Inventory2Outlined } from "@mui/icons-material";
import { GetUsersIdentity } from "../../services/ApiService";
import logOutIcon from '../../assets/mwa/Logout_icon.svg'
import clsx from "clsx";
import { useStyle } from "../../styles/style";

function ResponsiveAppBar() {
  const [anchorElNav, setAnchorElNav] = React.useState<null | HTMLElement>(null);
  const [anchorElNavs, setAnchorElNavs] = React.useState<null | HTMLElement>(null);
  const [anchorElUser, setAnchorElUser] = React.useState<null | HTMLElement>(null);
  const [graphData, setGraphData] = useState<any>();
  const [hasRights, setHasRights ] = useState(false);
  const [openDrawer, setOpenDrawer] = useState(false);
  const matches = useMediaQuery('(max-width:600px)');
  const matchesTab = useMediaQuery('(max-width:768px)');
  const { instance, accounts } = useMsal();
  const navigate = useNavigate();
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [showAccessPackageBtn, setshowAccessPackageBtn] = useState<Boolean>(false);
  const { theme } = msalResult;
  const { classes } = useStyle();
  
  let location = useLocation();

  const handleOpenNavMenus = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorElNav(event.currentTarget);
    setOpenDrawer(true);
  };
  const handleOpenUserMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorElUser(event.currentTarget);
  };
  const handleCloseNavMenu = () => {
    setAnchorElNav(null);
    setOpenDrawer(false);
  };
  const handleCloseNavMenus = () => {
    setAnchorElNav(null);
  };
  const handleCloseUserMenu = () => {
    setAnchorElUser(null);
  };
  const profile = () => {
    instance.acquireTokenSilent({ ...loginRequest, account: accounts[0] }).then((response: any) => {
      callMsGraph(response.accessToken).then((response: any) => {
        setGraphData(response)
      });
    });
  }

  useEffect(() => {
    if (location.pathname === "/access-packages") {
      setshowAccessPackageBtn(true);
    } else {
      setshowAccessPackageBtn(false);
    }
  }, [location]);

  useEffect(() => { 
    profile(); 
    GetUserIdentityInformation() 
  }, [showAccessPackageBtn]);

  const GetUserIdentityInformation = async () => {
    const tokenAccess = await getIdToken();
    GetUsersIdentity(tokenAccess).then((r: any) => {
      if(r?.data?.isAdmin === true){
        setHasRights(true);
      }
    })
  }

  const toggleDrawer =  (event: React.KeyboardEvent | React.MouseEvent) => {
    if (event && event.type === 'keydown' &&  ((event as React.KeyboardEvent).key === 'Tab' || (event as React.KeyboardEvent).key === 'Shift')) {
      return;
    }
    setOpenDrawer(false);
  };

  const openAccessPackages = (x: any) => {
    navigate('/access-packages');
    handleCloseNavMenus();
    setOpenDrawer(false)
   }

  function logOut(logoutType: string) {
    setAnchorEl(null);

    if (logoutType === "popup") {
      instance.logoutPopup({
        mainWindowRedirectUri: "/"
      });
    } else if (logoutType === "redirect") {
      instance.logoutRedirect();
    }
  }

  return (
    <AppBar position="static" style={{backgroundColor:theme?.Style?.headerBg}} elevation={0}>
      <Container maxWidth="xl">
        <Toolbar disableGutters sx={{display:'flex', justifyContent:'space-between', width:'100%', minHeight:'80px !important'}}>
          <Grid display={'flex'} justifyContent={'start'} item style={{ maxWidth: '100%', height: 'auto', cursor:'pointer' }}>
             <img  
              onClick={() => navigate("/") } 
              src={theme? theme?.FileAccess?.baseUrl + theme?.LandingPage?.logoUrl + `?`+ theme?.FileAccess?.sasToken : Logo}
              alt="logo" 
              style={{ maxWidth: theme?.Style?.maxWidth, height: '100%', objectFit: 'contain' }}
              />
          </Grid>
          <Grid item sx={{display:'flex'}}>
            <Box sx={{ flexGrow: 1, display: { xs: 'flex', md: 'none' } }}>
              <IconButton size="large" aria-label="account of current user" aria-controls="menu-appbar" aria-haspopup="true" onClick={handleOpenNavMenus} color="inherit">
                <MenuIcon />
              </IconButton>
              <Menu id="menu-appbar" anchorEl={anchorElNavs} anchorOrigin={{vertical: 'bottom',horizontal: 'left'}} keepMounted
                transformOrigin={{
                  vertical: 'top',
                  horizontal: 'left',
                }}
                open={Boolean(anchorElNavs)}
                onClose={handleCloseNavMenus}
                sx={{
                  display: { xs: 'block', md: 'none' },
                }}
              >
              </Menu>
            </Box>

            <Box sx={{ flexGrow: 1, paddingRight:'16px', display: { xs: 'none', md: 'flex' }  }}>
              {
                hasRights && !showAccessPackageBtn ? 
                <button 
                  style={{marginRight:"32px", cursor:"pointer", backgroundColor:theme?.Style?.btnColor, padding:"8px 16px", color:"white", fontWeight:'400', borderRadius:"15px", fontFamily:theme?.Style?.font, fontSize:'12px', textTransform:"capitalize", textDecoration:"none"}} 
                  rel="noreferrer"
                  onClick={()=>openAccessPackages('/access-packages')}
                  >
                  Access Packages
                </button>
                :
                <></>
              }
              {
                hasRights && showAccessPackageBtn ? 
                <button 
                  style={{marginRight:"32px", cursor:"pointer", backgroundColor:theme?.Style?.btnColor, padding:"8px 16px", color:"white", fontWeight:'400', borderRadius:"15px", fontFamily:theme?.Style?.font, fontSize:'12px', textTransform:"capitalize", textDecoration:"none"}} 
                  rel="noreferrer"
                  onClick={()=>{ navigate('/')}}>
                  Application List
                </button>
                :
                <></>
              }
              <Tooltip title="">
                <IconButton onClick={handleOpenUserMenu} sx={{ p: 0 }}>
                  {/* <img src={logout} width={'32px'} alt="" /> */}
                  <svg xmlns="http://www.w3.org/2000/svg" width="33" height="34" viewBox="0 0 43 44">
                    <defs>
                      <clipPath id="clip-path">
                        <rect id="Rectangle_48" data-name="Rectangle 48" width="31" height="31" transform="translate(-0.037 -0.037)" fill="#fff"/>
                      </clipPath>
                    </defs>
                    <g id="Group_924" data-name="Group 924" transform="translate(-1570 -33)">
                      <rect id="Rectangle_56" data-name="Rectangle 56" width="43" height="44" rx="10" transform="translate(1570 33)" fill={theme?.Style?.btnColor}/>
                      <g id="Group_147" data-name="Group 147" transform="translate(1578.037 42.037)">
                        <g id="Group_138" data-name="Group 138" transform="translate(-2 -2)" clip-path="url(#clip-path)">
                          <path id="Path_137" data-name="Path 137" d="M83.842,53.95a4.923,4.923,0,1,1,4.923-4.923,4.928,4.928,0,0,1-4.923,4.923m0-8.605a3.682,3.682,0,1,0,3.682,3.682,3.687,3.687,0,0,0-3.682-3.682" transform="translate(-68.287 -37.071)" fill="#fff"/>
                          <path id="Path_138" data-name="Path 138" d="M13.082,26.162A13.081,13.081,0,1,1,26.162,13.081,13.1,13.1,0,0,1,13.082,26.162m0-24.921a11.84,11.84,0,1,0,11.84,11.84A11.854,11.854,0,0,0,13.082,1.24" transform="translate(2.473 2.474)" fill="#fff"/>
                          <path id="Path_139" data-name="Path 139" d="M44.244,160.469a12.986,12.986,0,0,1-9.309-3.894.62.62,0,0,1-.174-.514,7.189,7.189,0,0,1,7.118-6.27h4.6a7.166,7.166,0,0,1,7.136,6.392.62.62,0,0,1-.181.509,13,13,0,0,1-9.192,3.777m-8.207-4.548a11.835,11.835,0,0,0,16.307.1,5.927,5.927,0,0,0-5.863-4.99h-4.6a5.947,5.947,0,0,0-5.842,4.889" transform="translate(-28.689 -131.834)" fill="#fff"/>
                        </g>
                      </g>
                    </g>
                  </svg>
                  <Typography variant="subtitle1" style={{ color: '#fff', marginRight: 30,paddingLeft:10, fontFamily: theme?.Style?.font, fontSize:'16px', fontWeight:'500' }}> {hasRights ? 'Admin' : 'User'} | {graphData?.surname} </Typography>
                </IconButton> 
              </Tooltip>

              <Menu sx={{ mt: '45px' }} id="menu-appbar" anchorEl={anchorElUser} anchorOrigin={{ vertical: 'top',horizontal: 'right' }} keepMounted transformOrigin={{ vertical: 'top', horizontal: 'right'}}  open={Boolean(anchorElUser)} onClose={handleCloseUserMenu}>
                <MenuItem onClick={() => logOut("redirect") } key="logoutRedirect" sx={{padding:'10px 24px', fontFamily: theme?.Style?.font, fontSize:'12px'}}>
                  <svg xmlns="http://www.w3.org/2000/svg" width="33" height="34" viewBox="0 0 43 44">
                    <defs>
                      <clipPath id="clip-path">
                        <rect id="Rectangle_48" data-name="Rectangle 48" width="31" height="31" transform="translate(-0.037 -0.037)" fill="#fff"/>
                      </clipPath>
                    </defs>
                    <g id="Group_924" data-name="Group 924" transform="translate(-1570 -33)">
                      <rect id="Rectangle_56" data-name="Rectangle 56" width="43" height="44" rx="10" transform="translate(1570 33)" fill={theme?.Style?.btnColor}/>
                      <g id="Group_147" data-name="Group 147" transform="translate(1578.037 42.037)">
                        <g id="Group_138" data-name="Group 138" transform="translate(-2 -2)" clip-path="url(#clip-path)">
                          <path id="Path_137" data-name="Path 137" d="M83.842,53.95a4.923,4.923,0,1,1,4.923-4.923,4.928,4.928,0,0,1-4.923,4.923m0-8.605a3.682,3.682,0,1,0,3.682,3.682,3.687,3.687,0,0,0-3.682-3.682" transform="translate(-68.287 -37.071)" fill="#fff"/>
                          <path id="Path_138" data-name="Path 138" d="M13.082,26.162A13.081,13.081,0,1,1,26.162,13.081,13.1,13.1,0,0,1,13.082,26.162m0-24.921a11.84,11.84,0,1,0,11.84,11.84A11.854,11.854,0,0,0,13.082,1.24" transform="translate(2.473 2.474)" fill="#fff"/>
                          <path id="Path_139" data-name="Path 139" d="M44.244,160.469a12.986,12.986,0,0,1-9.309-3.894.62.62,0,0,1-.174-.514,7.189,7.189,0,0,1,7.118-6.27h4.6a7.166,7.166,0,0,1,7.136,6.392.62.62,0,0,1-.181.509,13,13,0,0,1-9.192,3.777m-8.207-4.548a11.835,11.835,0,0,0,16.307.1,5.927,5.927,0,0,0-5.863-4.99h-4.6a5.947,5.947,0,0,0-5.842,4.889" transform="translate(-28.689 -131.834)" fill="#fff"/>
                        </g>
                      </g>
                    </g>
                  </svg>
                  {/* <img width={'24px'} src={logOutIcon} alt="Logout" style={{paddingRight:'16px'}}/> */}
                  Log out
                </MenuItem>
              </Menu>
            </Box>
            <Language/>
            <Grid item>
              <Drawer
                variant="temporary"
                anchor={'right'}
                open={openDrawer}
                onClose={toggleDrawer}
                sx={{
                  display: { xs: 'flex', sm: 'flex', md:'flex', width:'50px' },
                  '& .MuiDrawer-paper': { boxSizing: 'border-box' },
                  '& .css-i9fmh8-MuiBackdrop-root-MuiModal-backdrop' : {backgroundColor:"#262626d6"},
                  '& .css-919eu4' :{backgroundColor:"#50505024"}
                }}
                classes={{
                  paper: clsx((!matches && !matchesTab) ? classes.drawerForm : ((matchesTab && !matches) ? classes.drawerMenuTablet : classes.drawerFormMobile)), 
                  }}>
                   
                    <Grid container justifyContent={'space-between'} display={'flex'} paddingRight={'8px'} pl={'8px'} paddingTop={'8px'} >
                       <Grid item paddingRight={'8px'} paddingTop={'8px'}>
                          <div> {graphData?.displayName} </div>
                        </Grid>
                        <Grid item paddingRight={'8px'} paddingTop={'8px'} onClick={handleCloseNavMenu} style={{cursor:'pointer'}}>
                          <CloseOutlined/>
                        </Grid>
                    </Grid>
                    <Divider sx={{my:2}}/>
                  <List>
                    <ListItem sx={{display:'flex', flexDirection:'column', justifyContent:'start'}}>
                      <ListItemText sx={{width:'100%', display:'flex !important', direction:'column'}}>
                        <div style={{display:'flex'}}>
                          <Inventory2Outlined sx={{color:'lightgreen', marginLeft:'20px'}}/>
                          {
                            hasRights && !showAccessPackageBtn? 
                            <MenuItem onClick={() => navigate("/access-packages") } key="access-packages" sx={{padding:'0px 24px', fontFamily:theme?.Style?.font, fontSize:'12px'}}>
                              Access Package
                            </MenuItem>
                            :
                            <></>
                          }
                          {
                            hasRights && showAccessPackageBtn? 
                            <MenuItem onClick={() => navigate("/") } key="access-packages" sx={{padding:'0px 24px', fontFamily:theme?.Style?.font, fontSize:'12px'}}>
                              Application Lists
                            </MenuItem>
                            :
                            <></>
                          }
                        </div>
                      </ListItemText>
                      <ListItemText sx={{width:'100%'}}> 
                          <MenuItem onClick={() => logOut("redirect") } key="logoutRedirect" sx={{padding:'10px 24px', fontFamily:theme?.Style?.font, fontSize:'12px'}}>
                            <img width={'24px'} src={logOutIcon} alt="Logout" style={{paddingRight:'16px'}}/>Log out
                          </MenuItem>
                      </ListItemText>
                    </ListItem>
                  </List>
              </Drawer>
            </Grid>
          </Grid>
          
        </Toolbar>
      </Container>
    </AppBar>
  );
}
export default ResponsiveAppBar;
