import i18n from "i18next";
import LanguageDetector from "i18next-browser-languagedetector";
import { initReactI18next } from "react-i18next";

import norsk from './norsk.json'
import english from './english.json'
import svenska from './svenska.json'

i18n.use(LanguageDetector)
  .use(initReactI18next)
  .init({
    // we init with resources
    resources: {
      no: {
        translations: norsk
      },
      se: {
        translations: svenska
      },
      en: {
        translations: english
      }
    },
    // fallbackLng: "no",

    // have a common namespace used around the full app
    ns: ["translations"],
    defaultNS: "translations",

    keySeparator: false, // we use content as keys

    interpolation: {
      escapeValue: false
    }
  });

export default i18n;