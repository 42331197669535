import styled from '@emotion/styled';
import { Card, CardContent, CardMedia, CircularProgress, Drawer, Grid, Pagination, Typography, useMediaQuery } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { GetAllApplications, GetUsersIdentity } from '../../services/ApiService';
import { useTranslation } from 'react-i18next';
import { useStyle } from '../../styles/style';
import clsx from 'clsx';
import { EditApplicationCard } from '../EditCard/EditApplicationCard';
import { Err } from '../Error/Err';
import i18n from '../../i18n/i18n';
import { Button } from '../Button/button';
import { getIdToken, msalResult } from '../../authConfig';

 const ApplicationCard = () => {
  
  let [applicationDetails, setApplicationDetails] = useState([]);
  const [loading, setLoading] = useState(false);
  const [profile, setProfile] = useState(false);
  const [error, setError] = useState(false); 
  const [createNewApp, setcreateNewApp] = useState(false); 
  const [openDrawer, setOpenDrawer] = useState(false);
  const [isHoveredPD, setIsHoveredPD] = useState(true);
  const [isHoveredPL, setIsHoveredPLC] = useState(true);
  const [isHoveredSC, setIsHoveredSC] = useState(true);
  const [isHoveredVK, setIsHoveredVK] = useState(true);
  const [selectedCardIndex, setSelectedCardIndex] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);
  const [perPage, setPerPage] = useState(15);
  const [searchTerm, setSearchTerm] = useState('');
  const [cardDetails, setCardDetails] = useState(null);
  const matches = useMediaQuery('(max-width:600px)');
  const matchesTab = useMediaQuery('(max-width:768px)');
  const startIndex = (currentPage - 1) * perPage;
  const endIndex = startIndex + perPage;
  const [securityGrp, setsecurityGrp] = useState(null);
  const { theme } = msalResult;

  const { classes } = useStyle();

  const fetchAppData = async () => {
    setLoading(true);
    const tokenAccess = await getIdToken();
    const res = await GetAllApplications(tokenAccess);
    if(res === 401){
      setError(true);
    }
     setLoading(false);
    setApplicationDetails(res?.data);
  };

  const Profile = async () => {
    const tokenAccess = await getIdToken();
    const res = await GetUsersIdentity(tokenAccess);
    setsecurityGrp(res.data);
    if(res.data.preferredLanguage === 'en-GB'){
      i18n.changeLanguage('en-GB');
    } else if(res.data.preferredLanguage === 'sv'){
      i18n.changeLanguage('se');
    } else if(res.data.preferredLanguage === 'no'){
      i18n.changeLanguage('no');
    }

    if (res?.data?.isAdmin === true) {
      return setProfile(true)
    } else {
      setProfile(false);
    }
    return res.data;
  }

  useEffect(() => { 
    setLoading(true);
    fetchAppData();
    Profile();
  }, []); 

  const { t } = useTranslation();

  const filteredData = applicationDetails ? applicationDetails?.filter((item : any) => {
    if (searchTerm === '') return true;
  }): [];

  const errApp = (err: boolean) => {
    setError(true);
  }

  const paginatedData = applicationDetails?.slice(startIndex, endIndex);

  const handleHover = (hoverState: any, type: any, id:any, index: any) => { 

      if (type === 'PrepDash') {
        setIsHoveredPD(hoverState);
        setIsHoveredPLC(false);
        setIsHoveredSC(false);
        setIsHoveredVK(false);
        setSelectedCardIndex(index)
      } else if (type === 'PLC') {
        setSelectedCardIndex(index)
        setIsHoveredPD(false);
        setIsHoveredSC(false);
        setIsHoveredVK(false);
      } else if (type === 'VannKiosk') {
        setIsHoveredVK(hoverState);
        setSelectedCardIndex(index)
        setIsHoveredPD(false);
        setIsHoveredPLC(false);
        setIsHoveredSC(false);
      } else {
        setIsHoveredSC(hoverState);
        setIsHoveredPD(false);
        setIsHoveredPLC(false);
        setIsHoveredVK(false);
        setSelectedCardIndex(index)
      }

  };

  const openAddApplication = (x: any) => {
    setOpenDrawer(true);
    setcreateNewApp(true);
    setCardDetails(null);
  }

  const handleHoverOver = (hoverState: any, type: any, id:any, index: any) => {
    if (type === 'PrepDash') {
      setIsHoveredPD(hoverState);
      setIsHoveredPLC(false);
      setIsHoveredSC(false);
      setIsHoveredVK(false);
      setSelectedCardIndex(null)
    } else if (type === 'PLC') {
      setSelectedCardIndex(null)
      setIsHoveredPD(false);
      setIsHoveredSC(false);
      setIsHoveredVK(false);
    } else if (type === 'VannKiosk') {
      setIsHoveredVK(hoverState);
      setSelectedCardIndex(null)
      setIsHoveredPD(false);
      setIsHoveredPLC(false);
      setIsHoveredSC(false);
    } else {
      setIsHoveredSC(hoverState);
      setIsHoveredPD(false);
      setIsHoveredPLC(false);
      setIsHoveredVK(false);
      setSelectedCardIndex(null)
    }
  }

  const pageCount = isNaN(filteredData?.length) ? 0 : Math.ceil(filteredData?.length / perPage);


  const handlePageChange = (event: any, value: React.SetStateAction<number>) => {
    setCurrentPage(value);
  };

  const handleFilterChange = (event: any) => {
    setSearchTerm(event.target.value);
    setCurrentPage(1);
  };

  const handleOpen = (cardInformation: any) => {
    setCardDetails(cardInformation);
    setOpenDrawer(true)
    setcreateNewApp(false);
  };
  const handleCloseEdit = () => { 
    setOpenDrawer(false);
    setCardDetails(null);
      if(paginatedData.length === 0){
        setCurrentPage(currentPage -1)
      }
  }

  const reload = (x: any) => {
    fetchAppData();
  }

  const AppCard = styled(Card)(
    ({ theme }) => ({
      maxWidth: 30/0,
      width: 215,
      height: 230,
      borderRadius:10,
      cursor:"pointer",
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'space-between',
      transition: 'transform 0.3s ease-in-out',
      boxShadow: 'none',
  
      '&:hover': {
        // transform: 'scale(1.05)',
        // boxShadow: 'inset 0px 0px 10px rgba(0, 0, 0, 0.2)',
      },
      '@media(max-width: 600px)':{
       width:'200px'
      },
      '@media(max-width: 500px)':{
        width:'170px'
       }
    })
  );
  
  const AppImage = styled(CardMedia)( ({ theme }) => ({
      // paddingTop: '56.25%', // 16:9 aspect ratio
      backgroundSize: 'contain',
      '&::before': {
        content: '""',
        position: 'absolute',
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
        // backgroundColor: '#441D70cc', // Adjust the color and opacity as needed
        zIndex: 1,
        height:'152px',
        borderRadius:'10px 10px 0px 0px',
      },
      '& img': {
        width: '100%',
        height: '100%',
        objectFit: 'contain',
      },
      height: '100px',
      '@media(max-width: 600px)':{
        height:'100px'
       },
       '@media(max-width: 500px)':{
        height:'50px',
       }
    })
  );

  const AppImage1 = styled(CardMedia)( ({ theme }) => ({
    backgroundSize: 'contain',
    '& img': {
      width: '100%',
      height: '100%',
      objectFit: 'contain',
    },
    height: '130px',
    marginTop:'auto',
  })
);
  
  const AppCardContent = styled(CardContent)({
    display: 'flex',
    flexDirection: 'column',
    backgroundColor:"#FFFF",
    paddingBottom:'0px !important',
    textAlign: 'start',
    padding:'0px',
    height:'90px'
  });
  
  const AppTitle = styled(Typography)({
    font: `normal normal bold 16px/24px ${theme?.Style?.font}`,
    textTransform:'capitalize',
    color:'#0C1732',
    '@media(max-width: 600px)':{
      font: `normal normal bold 16px/16px ${theme?.Style?.font}`,
    },
     '@media(max-width: 500px)':{
      font: `normal normal bold 16px/16px ${theme?.Style?.font}`,
    },
  });

  const AppLogo = styled(Typography)({
    fontWeight: 600,
    paddingLeft:"20%",
    fontSize:"12px",
    paddingTop:"30%",
    fontFamily: `normal normal bold 24px/29px ${theme?.Style?.font}`,
    color:"white",
    backgroundColor:"#695185",   
    display:'inline',
    padding:"8px 6px",
    borderRadius:"5px"
  });
  
  const AppDescription = styled(Typography)({
    lineHeight: '16px !important',
    color:"#0C1732",
    font: `normal normal 400 10px/22px ${theme?.Style?.font}`,
  });

  const Title = styled.h2`
    margin: 0;
    font-weight: 500;
    color: #000;
    text-align: start;
    font: normal normal bold 24px/60px ${theme?.Style?.font};
    @media(max-width: 600px){
      font: normal normal bold 20px/36px ${theme?.Style?.font} !important;
    }
  `;

  const TopSectionContainer = styled.div`
    display: grid;
    justify-content: space-between;
    flex-direction: row;
    gap: 20px;
    grid-template-columns: repeat(5, 4fr);
    @media screen and (max-width: 1024px) {
      grid-template-columns: repeat(3, 2fr);
      gap: 16px;
    };
    @media screen and (max-width: 1182px) {
      grid-template-columns: repeat(4, 2fr);
      gap: 16px;
    };
    @media screen and (max-width: 768px) {
      grid-template-columns: repeat(3, 1fr);
      gap: 16px;
    };
    @media screen and (max-width: 960px) {
      grid-template-columns: repeat(3, 1fr);
      gap: 16px;
    };
    @media screen and (max-width: 425px) {
      grid-template-columns: repeat(2, 1fr);
      gap: 16px;
    }
  `;

  const toggleDrawer =  (event: React.KeyboardEvent | React.MouseEvent) => {
    if (event && event.type === 'keydown' &&  ((event as React.KeyboardEvent).key === 'Tab' || (event as React.KeyboardEvent).key === 'Shift')) {
      return;
    }
    setOpenDrawer(false);
    setcreateNewApp(false);
  };

  return(  
    <Grid width={'100%'}>
      {error ? 
        (
          <Err isOpen={true}/>
        ) : (
          <Grid container sx={{ pt:{md: '28px', xs:'0px', lg:'32px'}}} justifyContent={'center'} >
            <Grid display={'flex'} justifyContent={'space-between'} flexDirection={'row'} width={'100%'} container spacing={2} sx={{ pl: {xs:"10px", md:"6%", lg:""}, pb: {xs:"12px", lg:""} }}>
              <Grid item>
                <Title>{t('applications')}</Title>
              </Grid>
              {
                profile && !loading ?
                <Grid item pl={{sm:'0px !important', xs:'0px !important'}} pt={'1% !important'} pr={{sm:'10% !important', xs:'8% !important'}}>
                  <Button size = {matches ? 12 : 16} bgColor={theme?.Style?.btnColor} marginTopSize = {0} onClick={openAddApplication}> Add Application </Button>
                </Grid> : 
                <></>
              }
            </Grid>
            {
              loading ? 
                <CircularProgress/>
              :               
                (
                  applicationDetails.length > 0 ?
                    <TopSectionContainer>
                      {paginatedData.map((application: any, index: any) => (
                        <Grid item xs={12} md={2.4} lg={3} key={application.applicationId} display={'flex'} justifyContent={'center'} maxWidth={'100% !important'} position={'relative'}>
                          <AppCard> 
                              { application?.imageUrl ? (
                                <AppImage1 image={application.imageUrl} />
                                ) : (
                                  <AppImage theme={application?.typeColorHighlite}>
                                    {
                                      // application?.typeAbbreviation === 'CC' ? (
                                      //   <svg xmlns="http://www.w3.org/2000/svg"  width="256" height="165" viewBox="0 0 400 257.813">
                                      //     <g id="Group_1033" data-name="Group 1033" transform="translate(-11100 -1176)">
                                      //       <rect id="AdobeStock_313642835" width="400" height="257.813" transform="translate(11100 1176)" 
                                      //       fill={
                                      //         application?.typeAbbreviation && 
                                      //         theme?.ApplicationPage && 
                                      //         theme?.ApplicationPage[application?.typeAbbreviation] && 
                                      //         theme?.ApplicationPage[application?.typeAbbreviation].primary ?
                                      //         theme?.ApplicationPage[application?.typeAbbreviation].primary : 
                                      //         '#ffff' }/>
                                      //       <path id="Intersection_11" data-name="Intersection 11" d="M180.7,236.109A344.2,344.2,0,0,0,93.182,76.7L167.62,0A453.669,453.669,0,0,1,288.375,236.109Zm-180.7,0V103.157q.906.645,1.81,1.3c.938.644,1.877,1.289,2.815,2,1.939,1.353,3.879,2.836,5.755,4.383,1.251.9,2.5,1.869,3.691,2.9.062.064.187.129.25.193,2.377,1.933,4.754,3.867,7.006,5.93,2.752,2.32,5.441,4.769,8.069,7.283s5.192,5.027,7.694,7.67l-.019.019c4.253,4.383,8.32,8.895,12.26,13.535,2.69,3.223,5.255,6.445,7.819,9.8,2.44,3.223,4.817,6.445,7.131,9.8,0,.065.063.129.126.193,3.44,5.027,6.755,10.248,9.946,15.533a258.439,258.439,0,0,1,14.512,28.552c3.315,7.476,6.193,15.146,8.758,22.944l.3.926Z" transform="translate(11100 1197.703)" fill="#1d4ab9"/>
                                      //     </g>
                                      //   </svg>
                                      // ) : 
                                      // application?.typeAbbreviation === 'VK' ? (
                                      //   <svg xmlns="http://www.w3.org/2000/svg" width="256" height="165" viewBox="0 0 256 165">
                                      //   <g id="Group_1044" data-name="Group 1044" transform="translate(-10136 -1919)">
                                      //     <path id="AdobeStock_313642835" d="M10,0H246a10,10,0,0,1,10,10V165a0,0,0,0,1,0,0H0a0,0,0,0,1,0,0V10A10,10,0,0,1,10,0Z" transform="translate(10136 1919)" fill="#1d4ab9"/>
                                      //     <path id="Intersection_6" data-name="Intersection 6" d="M2133.466,432.859A217.834,217.834,0,0,0,2075.8,331.112l49.045-48.953a286.506,286.506,0,0,1,79.561,150.7Zm-119.056,0V348q.6.412,1.193.83c.618.411,1.237.823,1.855,1.275,1.278.864,2.556,1.81,3.792,2.8.824.576,1.649,1.193,2.432,1.851.041.041.123.082.165.123,1.566,1.234,3.132,2.468,4.616,3.785,1.813,1.481,3.585,3.044,5.316,4.648s3.421,3.209,5.069,4.9l-.012.012c2.8,2.8,5.481,5.677,8.078,8.639,1.772,2.057,3.462,4.114,5.152,6.253,1.607,2.057,3.174,4.114,4.7,6.253,0,.041.042.082.083.123,2.267,3.209,4.451,6.541,6.553,9.914a163.043,163.043,0,0,1,9.562,18.224,154.3,154.3,0,0,1,5.77,14.645l.2.591Z" transform="translate(8121.59 1651.141)" fill="#1785ec"/>
                                      //   </g>
                                      // </svg>                      
                                      // ) :
                                      // application?.typeAbbreviation === 'PD' ? (
                                      //   <svg xmlns="http://www.w3.org/2000/svg" width="256" height="165" viewBox="0 0 256 165">
                                      //     <g id="Group_1041" data-name="Group 1041" transform="translate(-10136 -1654)">
                                      //       <path id="AdobeStock_313642835" d="M10,0H246a10,10,0,0,1,10,10V165a0,0,0,0,1,0,0H0a0,0,0,0,1,0,0V10A10,10,0,0,1,10,0Z" transform="translate(10136 1654)" fill="#441d70"/>
                                      //       <path id="Intersection_5" data-name="Intersection 5" d="M2133.466,432.859A217.834,217.834,0,0,0,2075.8,331.112l49.045-48.953a286.506,286.506,0,0,1,79.561,150.7Zm-119.056,0V348q.6.412,1.193.83c.618.411,1.237.823,1.855,1.275,1.278.864,2.556,1.81,3.792,2.8.824.576,1.649,1.193,2.432,1.851.041.041.123.082.165.123,1.566,1.234,3.132,2.468,4.616,3.785,1.813,1.481,3.585,3.044,5.316,4.648s3.421,3.209,5.069,4.9l-.012.012c2.8,2.8,5.481,5.677,8.078,8.639,1.772,2.057,3.462,4.114,5.152,6.253,1.607,2.057,3.174,4.114,4.7,6.253,0,.041.042.082.083.123,2.267,3.209,4.451,6.541,6.553,9.914a163.043,163.043,0,0,1,9.562,18.224,154.3,154.3,0,0,1,5.77,14.645l.2.591Z" transform="translate(8121.59 1386.141)" fill="#7732c6"/>
                                      //     </g>
                                      //   </svg>
                                      // ) : 
                                      // (
                                      //   <svg xmlns="http://www.w3.org/2000/svg" width="256" height="165" viewBox="0 0 256 165">
                                      //     <g id="Group_1038" data-name="Group 1038" transform="translate(-10719 -1415)">
                                      //       <rect id="AdobeStock_313642835" width="256" height="165" transform="translate(10719 1415)" fill="#1d7058"/>
                                      //       <path id="Intersection_8" data-name="Intersection 8" d="M2133.466,432.859A217.834,217.834,0,0,0,2075.8,331.112l49.045-48.953a286.506,286.506,0,0,1,79.561,150.7Zm-119.056,0V348q.6.412,1.193.83c.618.411,1.237.823,1.855,1.275,1.278.864,2.556,1.81,3.792,2.8.824.576,1.649,1.193,2.432,1.851.041.041.123.082.165.123,1.566,1.234,3.132,2.468,4.616,3.785,1.813,1.481,3.585,3.044,5.316,4.648s3.421,3.209,5.069,4.9l-.012.012c2.8,2.8,5.481,5.677,8.078,8.639,1.772,2.057,3.462,4.114,5.152,6.253,1.607,2.057,3.174,4.114,4.7,6.253,0,.041.042.082.083.123,2.267,3.209,4.451,6.541,6.553,9.914a163.043,163.043,0,0,1,9.562,18.224,154.3,154.3,0,0,1,5.77,14.645l.2.591Z" transform="translate(8704.59 1146.841)" fill="#09b885"/>
                                      //     </g>
                                      //   </svg>
                                      // )
                                    }

                                        <svg xmlns="http://www.w3.org/2000/svg"  width="256" height="165" viewBox="0 0 400 257.813">
                                          <g id="Group_1033" data-name="Group 1033" transform="translate(-11100 -1176)">
                                            <rect id="AdobeStock_313642835" width="400" height="257.813" transform="translate(11100 1176)" 
                                            fill={
                                              application?.typeAbbreviation && 
                                              theme?.ApplicationPage && 
                                              theme?.ApplicationPage[application?.typeAbbreviation] && 
                                              theme?.ApplicationPage[application?.typeAbbreviation].primary ?
                                              theme?.ApplicationPage[application?.typeAbbreviation].primary : 
                                              '#ffff' }/>
                                            <path id="Intersection_11" data-name="Intersection 11" d="M180.7,236.109A344.2,344.2,0,0,0,93.182,76.7L167.62,0A453.669,453.669,0,0,1,288.375,236.109Zm-180.7,0V103.157q.906.645,1.81,1.3c.938.644,1.877,1.289,2.815,2,1.939,1.353,3.879,2.836,5.755,4.383,1.251.9,2.5,1.869,3.691,2.9.062.064.187.129.25.193,2.377,1.933,4.754,3.867,7.006,5.93,2.752,2.32,5.441,4.769,8.069,7.283s5.192,5.027,7.694,7.67l-.019.019c4.253,4.383,8.32,8.895,12.26,13.535,2.69,3.223,5.255,6.445,7.819,9.8,2.44,3.223,4.817,6.445,7.131,9.8,0,.065.063.129.126.193,3.44,5.027,6.755,10.248,9.946,15.533a258.439,258.439,0,0,1,14.512,28.552c3.315,7.476,6.193,15.146,8.758,22.944l.3.926Z" transform="translate(11100 1197.703)" 
                                            fill={
                                              application?.typeAbbreviation && 
                                              theme?.ApplicationPage && 
                                              theme?.ApplicationPage[application?.typeAbbreviation] && 
                                              theme?.ApplicationPage[application?.typeAbbreviation].secondary ?
                                              theme?.ApplicationPage[application?.typeAbbreviation].secondary : 
                                              '#ffff' }/>
                                          </g>
                                        </svg>
                                  </AppImage>
                                )
                            }
                                  {
                              profile ? 
                                <Grid sx={{position: 'absolute', right: 10, top: 125, backgroundColor: '#ffff', padding: '0px', borderRadius:'12px'}}>
                                  <div  onClick={ () => handleOpen(application)} style={{display:'flex', justifyContent:'flex-end',borderRadius:'5px', position:'relative', zIndex:'1' }}>
                                      <svg xmlns="http://www.w3.org/2000/svg" width="39" height="39" viewBox="0 0 51 51">
                                        <g id="Edit" transform="translate(8 8)">
                                          <g id="Icon" transform="translate(-480 -807)">
                                            <g id="Rectangle_56" data-name="Rectangle 56" transform="translate(480 807)" fill={theme?.Style?.btnColor} stroke="#fff" stroke-width="8">
                                              <rect width="35" height="35" rx="10" stroke="none"/>
                                              <rect x="-4" y="-4" width="43" height="43" rx="14" fill="none"/>
                                            </g>
                                            <g id="Icon_metro-pencil" data-name="Icon metro-pencil" transform="translate(485.429 813.071)" fill="none">
                                              <path d="M19.3,1.928A3.023,3.023,0,0,1,22.4,4.87a2.833,2.833,0,0,1-.62,1.765L20.539,7.811,16.2,3.693l1.239-1.177A3.193,3.193,0,0,1,19.3,1.928ZM3.81,15.46l-1.239,5.3,5.576-1.177L19.61,8.694,15.272,4.576Z" stroke="none"/>
                                              <path d="M 19.29973411560059 3.428037643432617 C 18.96803283691406 3.428037643432617 18.65374755859375 3.517732620239258 18.38523864746094 3.688236236572266 L 18.38014984130859 3.69306755065918 L 20.53892517089844 5.742961883544922 L 20.65413475036621 5.633562088012695 C 20.77509117126465 5.459831237792969 20.897705078125 5.201787948608398 20.897705078125 4.869749069213867 C 20.897705078125 4.074789047241211 20.18085479736328 3.428037643432617 19.29973411560059 3.428037643432617 M 15.27237987518311 6.644100189208984 L 5.168176651000977 16.23869705200195 L 4.568668365478516 18.80041122436523 L 7.421995162963867 18.19832229614258 L 17.43114852905273 8.693988800048828 L 15.27237987518311 6.644100189208984 M 19.29973411560059 1.928037643432617 C 21.01067352294922 1.928037643432617 22.397705078125 3.245079040527344 22.397705078125 4.869749069213867 C 22.397705078125 5.532009124755859 22.16710472106934 6.143117904663086 21.77811431884766 6.634788513183594 L 20.53892517089844 7.811478614807129 L 16.20176315307617 3.69306755065918 L 17.44095420837402 2.516378402709961 C 17.95874404907227 2.147008895874023 18.60226440429688 1.928037643432617 19.29973411560059 1.928037643432617 Z M 15.27237415313721 4.575578689575195 L 19.60953521728516 8.693988800048828 L 8.147045135498047 19.5783576965332 L 2.570703506469727 20.75503921508789 L 3.809894561767578 15.45994853973389 L 15.27237415313721 4.575578689575195 Z" stroke="none" fill="#fff"/>
                                            </g>
                                          </g>
                                        </g>
                                      </svg>
                                  </div>
                                
                                </Grid>
                                :
                                <></>
                              }                   
                            <AppCardContent className={classes.cardTitle} onMouseEnter={() => handleHover(true, application?.typeName, application?.applicationId, index)}
                                onMouseLeave={() => handleHoverOver(false, application?.typeName, application?.applicationId, index)}>
                              <Grid container p={0} spacing={2} width={'100%'} margin={'0px'} minHeight={'80px'} maxHeight={'80px'} >
                                <Grid key={index} item xs={4} margin={'0px !important'} paddingRight={'8px'} paddingTop={'24px !important'}>
                                    {
                                      <svg width="250" height="40"
                                      style={{fill: !isHoveredPL &&  index === selectedCardIndex ? application?.typeColorNormal === null ? '#1D7058' : application?.typeColorNormal : application?.typeColorHighlite === null ? '#09B885' : application?.typeColorHighlite }}
                                      >
                                        <rect x="0" y="0" rx="6" ry="6" width="42" height="40"/>
                                        <text fill="#ffffff" fontSize="18" fontWeight="700" fontFamily={theme?.Style?.font} textAnchor='middle' x="21" y="26">
                                          {application?.typeAbbreviation === null ? 'NA': application?.typeAbbreviation}
                                        </text>
                                      </svg>
                                    }
                                </Grid>
                                <Grid item pt={'20px !important'} pl={{md:'0px !important', xs:'10px !important', sm:'5px !important'}} xs={8} onClick={() => window.open(application.url,'_blank', )}>
                                    <AppTitle>{application?.typeName === null ? 'App' : application?.typeName}</AppTitle>
                                    <AppDescription>{application?.displayName}</AppDescription>
                                </Grid>
                              </Grid>
                            </AppCardContent>
                          </AppCard>
                        </Grid>
                      ))}
                    </TopSectionContainer> :
                    
                    <Grid item>
                      <Title>No Applications Found</Title>
                    </Grid>          
                )
            }
            <Grid container justifyContent={'start'} pt={2} pl={'6%'}>
              {
                !loading && pageCount > 1 ? (
                  <div>
                <Pagination
                 count={pageCount} page={currentPage} onChange={handlePageChange} />
              </div>
                ) : (
                  <></>
                )
              }              
            </Grid>
            <Grid item>
              <Drawer
                variant="temporary"
                anchor={'right'}
                open={openDrawer}
                onClose={toggleDrawer}
                sx={{
                  display: { xs: 'flex', sm: 'block', md:'flex' },
                  '& .MuiDrawer-paper': { boxSizing: 'border-box' },
                  '& .css-i9fmh8-MuiBackdrop-root-MuiModal-backdrop' : {backgroundColor:"#000000b0"},
                  '& .css-919eu4' :{backgroundColor:"#000000b0"}
                }}
                classes={{
                  paper: clsx((!matches && !matchesTab) ? classes.drawerForm : ((matchesTab && !matches) ? classes.drawerFormTablet : classes.drawerFormMobile)), 
                  }}>
                  <EditApplicationCard equipDetails={cardDetails} handleModalClose={handleCloseEdit} reload={reload} newApp={createNewApp} securityGrp={securityGrp} err={errApp}/>
              </Drawer>
            </Grid>
          </Grid>
        )
  
       }
    </Grid>
    )
};

export default ApplicationCard