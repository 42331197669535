import React, { useEffect, useState } from 'react';
import './App.css';
import { PageContainer,  } from './components/PageContainer/pageContainer';
import { MsalProvider } from '@azure/msal-react';
import { initializeMsal, msalResult, } from './authConfig';
import CookieConsent from 'react-cookie-consent';
import ConsentForm from './components/ConsentForm/ConsentForm';

function App() {
  const [loading, setLoading] = useState(true);
  const [open, setOpen] = React.useState(false);
  const openConsentForm = () =>{
   return setOpen(true);
  } 

  const { theme } = msalResult;

  const redirectToWebsite = (url: any) => {
    window.open(url, "_blank");
  }

  useEffect(() => {
    const initialize = async () => {
      try {
        await initializeMsal();
        setLoading(false);
      } catch (error) {
        console.error('Error during MSAL initialization:', error);
        setLoading(false);
      }
    };

    initialize();
  }, []);

  const { instance } = msalResult;

  if (loading) {
    // Render a loader or placeholder UI while fetching configuration
    return <div style={{display:'flex', justifyContent:'center', flexDirection:'column', height:'100vh'}}>
              <div style={{display:'flex', justifyContent:'center'}}>
                      Loading...
              </div>
            </div>;
  }

  if (!msalResult) {
    // Handle case where initialization failed
    return <div>Error initializing MSAL</div>;
  }    

  const handleCloseConsentForm = () => { console.log('here')
    return setOpen(false);
  }

  return (
    <MsalProvider instance={instance}>
            {/* <CookieConsent
              location="bottom"
              buttonText="Accept"
              declineButtonText="Decline"
              cookieName="myAppCookieConsent"
              style={{ background: "#0c1732", color: "#FFF", width:'30%', left:'1%', borderRadius:'2%'}}
              buttonStyle={{ background: "#09b885", color: "#ffff", margin:'0px 10px 20px 10px', fontSize: "14px", borderRadius:'10%' }}
              declineButtonStyle={{ fontSize: "14px", background:'#f32b2b', margin:'0px 10px 20px 10px', borderRadius:'10%' }}
              expires={150}
              enableDeclineButton
              onDecline={() => {
                console.log("Cookies declined");
              }}
              onAccept={() => {}}
            >
              <p style={{cursor:'pointer', marginRight:'auto', color: 'white', font:`normal normal 300 14px/16px ${theme?.Style?.font}`, margin:'0px', paddingLeft:'1%' }}> We use cookies to enhance your browsing experience, serve personalized content, and analyze our traffic. By clicking "Accept", you consent to our use of cookies.
              </p>
              <div style={{display:'flex', justifyContent:'space-between', marginTop:'3%'}}>
                <p onClick={()=>{redirectToWebsite(theme?.Footer?.CookiePolicy)}} style={{cursor:'pointer', marginRight:'auto', margin:'0px', color: '#09b885', font:`normal normal 300 14px/20px ${theme?.Style?.font}`, paddingLeft:'1%' }}>Cookie Policy</p>

                <p onClick={()=>{openConsentForm()}} style={{cursor:'pointer', marginRight:'auto', margin:'0px', color: '#09b885', font:`normal normal 300 14px/20px ${theme?.Style?.font}`, paddingLeft:'1%' }}>Manage </p>
              </div>
            </CookieConsent> */}

              { open && (
                <ConsentForm handleopen = {open} closeconsentform = { handleCloseConsentForm } />
              )}
      <div className="App">
        <PageContainer />
      </div>
    </MsalProvider>
  );
}

export default App;
