import { Backdrop, Button, Fade, Modal, Stack, Switch, css, styled } from '@mui/material';
import { useState } from 'react';
const grey = {
    50: '#F3F6F9',
    100: '#E5EAF2',
    200: '#DAE2ED',
    300: '#C7D0DD',
    400: '#B0B8C4',
    500: '#9DA8B7',
    600: '#6B7A90',
    700: '#434D5B',
    800: '#303740',
    900: '#1C2025',
  };

  const StyledBackdrop = styled(Backdrop)`
  z-index: -1;
  position: fixed;
  inset: 0;
  background-color: rgb(0 0 0 / 0.5);
  -webkit-tap-highlight-color: transparent;
`;

const ModalContent = styled('div')(
    ({ theme }) => css`
      font-family: 'IBM Plex Sans', sans-serif;
      font-weight: 500;
      text-align: start;
      position: relative;
      display: flex;
      flex-direction: column;
      gap: 8px;
      overflow: hidden;
      background-color: ${theme.palette.mode === 'dark' ? grey[900] : '#fff'};
      border-radius: 8px;
      border: 1px solid ${theme.palette.mode === 'dark' ? grey[700] : grey[200]};
      box-shadow: 0 4px 12px
        ${theme.palette.mode === 'dark' ? 'rgb(0 0 0 / 0.5)' : 'rgb(0 0 0 / 0.2)'};
      padding: 24px;
      color: ${theme.palette.mode === 'dark' ? grey[50] : grey[900]};

      & .modal-title {
        margin: 0;
        line-height: 1.5rem;
        margin-bottom: 8px;
      }

      & .modal-description {
        margin: 0;
        line-height: 1.5rem;
        font-weight: 400;
        color: ${theme.palette.mode === 'dark' ? grey[400] : grey[800]};
        margin-bottom: 4px;
      }
    `,
  );

  const style = {
    position: 'absolute' as 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 800,
  };

  const checkedIcon = (evt: any) => {
    return evt;
  }  

const ConsentForm = ({handleopen, closeconsentform} : any) => {
    const [open, setOpen] = useState(handleopen);
    const label = { slotProps: { input: { 'aria-label': 'Switch' } } };
  return(
  <Modal
    aria-labelledby="spring-modal-title"
    aria-describedby="spring-modal-description"
    open={open}
    closeAfterTransition
    slots={{ backdrop: StyledBackdrop }}
    >
    <Fade in={open}>
        <ModalContent sx={style}>
        <h2 id="spring-modal-title" className="modal-title">
            Customize Consent Preferences
        </h2>
        <hr style={{width:'100%'}}/>
        <span id="spring-modal-description" className="modal-description">
            We and our business partners use technologies including cookies to collect information about you for various purposes including Functional and Marketing.

            By Clicking 'Accept' button you give your consent for all these purposes. You can also choose to specify the purposes your consent to by ticking the checkbox next to the purpose.
        <br /> The cookies that are categorized as "Necessary" are stored on your browser as they are essential for enabling the basic functionalities of the site.
        </span>
        <hr style={{width:'100%'}}/>
        <div style={{display:'flex', flexDirection:'column'}}>
            <div style={{display:'flex', justifyContent:'space-between', width:'100%'}}>
            <h3 style={{fontSize:'15px'}} id="spring-modal-title" className="modal-title">
                Necessary Cookies
            </h3>
            <h4 style={{fontSize:'15px', color:'#09b885'}} id="spring-modal-title" className="modal-title">
                Always Active
            </h4>
            </div>
            <div style={{display:'flex', justifyContent:'space-between', width:'100%'}}>
            <span style={{fontSize:'14px'}} id="spring-modal-description" className="modal-description">
            Necessary cookies are required to enable the basic features of this site, such as providing secure log-in or adjusting your consent preferences. These cookies do not store any personally identifiable data.
            </span>
            </div>
        </div>
        <hr style={{width:'100%'}}/>
        <div style={{width:'100%', display:'flex', justifyContent:'space-around'}}>
            <div style={{display:'flex', flexDirection:'column'}}>
            <span>Functional </span>
            <Switch
                {...label}
                onClick={(evt) => checkedIcon(evt)}
            />
            </div>
            <div style={{display:'flex', flexDirection:'column'}}>
            <span>Marketing </span>
            <Switch
                {...label}
                onClick={(evt) => checkedIcon(evt)}
            />
            </div>
        </div>
        <div style={{width:'100%', display:'flex', justifyContent:'space-around', marginTop:'5%'}}>
            <Stack spacing={2} direction="row">
            {/* <Button onClick={handleClose} style={{background:'#f32b2b', color:'#FFFF'}}>Decline</Button> */}
            <Button onClick={closeconsentform} style={{background:'#09b885', color:'#FFFF'}}>Okay</Button>
            </Stack>
        </div>
        </ModalContent>
    </Fade>
    </Modal>
)
}
  

export default ConsentForm